<template>
  <v-row>
    <v-col cols="12">
      <v-form ref="form">
        <base-card>
          <v-card-title>Registrar Credenciales TEF</v-card-title>
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
                sm="3"
              >
                <v-text-field
                  ref="Cliente"
                  v-model="dataCredentials.client"
                  label="Cliente"
                  prepend-inner-icon="mdi-account-tie"
                  clearable
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-select
                  ref="rol"
                  v-model="dataCredentials.typeTef"
                  :items="typeTef"
                  item-text="textCredential"
                  item-value="valueCredential"
                  label="Tipo Tef"
                  prepend-inner-icon="mdi-printer-pos"
                  required
                />
              </v-col>
              <v-col
                cols="12"
                sm="1"
              >
                <br>
                <v-icon
                  class="mr-2"
                  color="green darken-3"
                  style="cursor: pointer"
                  @click="downloadFormatExcel(dataCredentials.typeTef)"
                >
                  mdi-microsoft-excel
                </v-icon>
              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <v-file-input
                  id="aExcel"
                  @change="loadExcel()"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="4"
              >
                <v-btn
                  class="ma-2"
                  style="background-color:rgb(204 33 40);color:white !important"
                  @click="sendCredentials()"
                >
                  Importar Credenciales
                </v-btn>
              </v-col>
            </v-row>
            <v-row
              v-if="importCredentialsProgress === true"
              class="fill-height"
              align-content="center"
              justify="center"
            >
              <v-col cols="6">
                <v-col
                  class="text-subtitle-1 text-center"
                  cols="12"
                >
                  Procesando y Guardando Credenciales
                </v-col>
                <v-progress-linear
                  color="red darken-4"
                  indeterminate
                  rounded
                  height="6"
                />
              </v-col>
            </v-row>
            <v-alert
              v-show="successAlert"
              type="success"
              text
            >
              {{ msgSuccessAlert }}
            </v-alert>
            <v-alert
              v-show="warningAlert"
              type="warning"
              text
            >
              {{ msgWarningAlert }}
            </v-alert>
          </v-card-text>
        </base-card>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
  import { listTef } from '@/data/listTef'
  import readXlsFile from 'read-excel-file'
  import { mapState } from 'vuex'

  export default {
    name: 'RegisterCredentialTef',

    data () {
      return {
        items: [],
        importCredentialsProgress: false,
        setDataCredentials: [],
        permisosRoles: ['administrador'],
        dataCredentials: {},
        typeTef: listTef,
        successAlert: false,
        warningAlert: false,
        msgSuccessAlert: '',
        msgWarningAlert: '',
      }
    },
    computed: {
      ...mapState('credentialsTef', ['statusResponse']),
    },
    watch: {
      statusResponse () {
        this.importCredentialsProgress = false
        const msg = this.$store.state.credentialsTef.statusResponse.Mensaje

        if (!this.$store.state.credentialsTef.statusResponse.Error) {
          this.msgSuccessAlert = msg
          this.successAlert = true
          setTimeout(() => {
            this.successAlert = false
          }, 3000)
        } else {
          this.msgWarningAlert = msg
          this.warningAlert = true
          setTimeout(() => {
            this.warningAlert = false
          }, 3000)
        }
        this.$store.dispatch('credentialsTef/getListCredentials')
      },
    },
    methods: {
      loadExcel () {
        const input = document.getElementById('aExcel')
        readXlsFile(input.files[0]).then((rows) => {
          this.items = rows
        })
      },
      sendCredentials () {
        this.importCredentialsProgress = true
        if (this.dataCredentials.typeTef === '1' || this.dataCredentials.typeTef === '3') { // Credibanco Smartpos
          this.buildCredentialSmartposCredibanco()
        }else if(this.dataCredentials.typeTef === '2'){ // Credibanco APPS
          this.buildCredentialCredibancoIntegracionApps()
        }else if (this.dataCredentials.typeTef === '4') {
          this.buildCredentialCredibancoIntegracionTramas()
        }else{
            this.importCredentialsProgress = false
        }

      },

      buildCredentialSmartposCredibanco () {
        this.items.forEach((column, index) =>

          column.forEach((p) =>

            this.setDataCredentials[index] = {
              client: this.dataCredentials.client,
              type_tef: this.dataCredentials.typeTef,
              data_integration: {
                code_store: column[0],
                password: column[1],
                username: column[2],
                client_id: column[3],
                url_token: column[4],
                grant_type: column[5],
                url_services: column[6],
                client_secret: column[7],
                codigo_comercio: column[8],
                codigo_terminal: column[9],
              },
              user: JSON.parse(atob(localStorage.getItem('uid'))).user,
            },
          ),
        )

        console.log(this.setDataCredentials)
        this.insertCredentials(this.setDataCredentials)
      },

      buildCredentialCredibancoIntegracionApps () {
        this.items.forEach((column, index) =>

          column.forEach((p) =>

            this.setDataCredentials[index] = {
              client: this.dataCredentials.client,
              type_tef: this.dataCredentials.typeTef,
              data_integration: {
                code_store: column[0],
                codigo_comercio: column[1],
                codigo_terminal: column[2],
              },
              user: JSON.parse(atob(localStorage.getItem('uid'))).user,
            },
          ),
        )

        console.log(this.setDataCredentials)
        this.insertCredentials(this.setDataCredentials)
      },
      buildCredentialCredibancoIntegracionTramas () {
        this.items.forEach((column, index) =>

          column.forEach((p) =>

            this.setDataCredentials[index] = {
              client: this.dataCredentials.client,
              type_tef: this.dataCredentials.typeTef,
              data_integration: {
                code_store: column[0],
                codigo_comercio: column[1],
                codigo_terminal: column[2],
              },
              user: JSON.parse(atob(localStorage.getItem('uid'))).user,
            },
          ),
        )

        console.log(this.setDataCredentials)
        this.insertCredentials(this.setDataCredentials)
      },

      async insertCredentials (dataCredentials) {
        await this.$store.dispatch('credentialsTef/insertCredentials', { credentials: dataCredentials })
      },
      downloadFormatExcel (typeTef) {
        let format
        if (typeTef === '1' || typeTef === '3') {
          format = 'formato/SmartPosCredibanco'
        }else if (typeTef === '2') {
          format = 'formato/CredibancoIntegracionApps'
        }else if (typeTef === '4') {
          format = 'formato/CredibancoIntegracionTramas'
        }
        const linkSource = '/' + format + '.xlsx'
        const downloadLink = document.createElement('a')
        const fileName =  format + '.xlsx'
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      },
    },
  }
</script>
